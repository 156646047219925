import React from 'react';
import './IntroContentBlock.scss';
import OnVisible from 'react-on-visible';
import { Arrow } from '../../Svg';
import { decodeEntities } from '../../../utils/helpers';
import GatsbyLink from '../../GatsbyLink';
import { RenderShortcode } from '../../RenderShortcode';
export const IntroContentBlock = (props) => {
  const {
    title,
    titleSeparateLine,
    subtitle,
    content,
    actions
  } = props;
  return (
    <OnVisible wrappingElement="section" percent={5} className="intro-content-block">
      <div className="wrap">
        {(titleSeparateLine && title) && <h2 className="intro-content-block-title">{decodeEntities(title)}</h2>}
        <div className="intro-content-block-content">
          <div className="left-col">
            {(!titleSeparateLine && title) && <h2 className="intro-content-block-title">{decodeEntities(title)}</h2>}
            {subtitle && <p className="opening">{subtitle}</p>}
          </div>
          <div className="right-col">
            {content && <RenderShortcode className="content" content={content} />}
            {actions && (
              <div className="actions">
                {actions.map((action, index) => {
                  const {
                    url: linkUrl,
                    title: linkTitle
                  } = action.link;
                  return (
                    <GatsbyLink key={index} className="action" to={linkUrl} decode>{linkTitle}</GatsbyLink>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </OnVisible>
  )
}
