import React from 'react';
import OnVisible from 'react-on-visible';
import './LeftRightPageLinks.scss';
import { decodeEntities } from '../../../utils/helpers';
import GatsbyLink from '../../GatsbyLink';

export const LeftRightPageLinks = (props) => {
  const {
    leftLink,
    rightLink
  } = props;
  return (
    <OnVisible wrappingElement="section" className="left-right-page-links">
      <div className="wrap">
        {leftLink && <GatsbyLink className="action left" to={leftLink.url} decode>{leftLink.title}</GatsbyLink>}
        {rightLink && <GatsbyLink className="action" to={rightLink.url} decode>{rightLink.title}</GatsbyLink>}
      </div>
    </OnVisible>
  )
}
