import React from 'react';
import OnVisible from 'react-on-visible';
import './PageLinks.scss';
import { ImagePass } from '../../Image/ImagePass';
import { decodeEntities } from '../../../utils/helpers';
import GatsbyLink from '../../GatsbyLink';
import { Arrow } from '../../Svg';

export const PageLinks = (props) => {
  const {
    title,
    introContent,
    pageLinks
  } = props;
  return (
    <OnVisible wrappingElement="section" percent={5} className="page-links">
      <div className="wrap">
        {title && <h2 className="page-links-title">{decodeEntities(title)}</h2>}
        {introContent && <div className="page-links-intro opening" dangerouslySetInnerHTML={{__html: introContent}} />}
        {pageLinks && (
          <div className="page-links-container">
            {pageLinks.map((pageLink, index) => {
              const { image, link } = pageLink;
              return (
                <GatsbyLink key={index} className="page-link" to={link.url}>
                  <ImagePass className="background-image" src={image} />
                  <span className="link" to={link.url}><span>{decodeEntities(link.title)}</span><Arrow /></span>
                </GatsbyLink>
              );
            })}
          </div>
        )}
      </div>
    </OnVisible>
  )
}
