import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import GatsbyLink from '../../GatsbyLink';
import { ImagePass } from '../../Image/ImagePass';
import { Arrow } from '../../Svg';
import './FooterTopBlock.scss';
import { decodeEntities } from '../../../utils/helpers';

export const FooterTopBlock = (props) => {
  let { image, content, link, useGlobal } = props;
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: wordpressAcfOptions(options: {}) {
        options {
          footerTopBlockImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          footerTopBlockContent
          footerTopBlockLink {
            url
            target
            title
          }
        }
      }
    }
  `);
  const { options = {} } = siteSettings;
  const {
    footerTopBlockImage = null,
    footerTopBlockContent = null,
    footerTopBlockLink = null
  } = options;
  if (useGlobal) {
    image = footerTopBlockImage;
    content = footerTopBlockContent;
    link = footerTopBlockLink;
  }
  return (
    <OnVisible wrappingElement="section" className="footer-top-block">
      <div className="wrap">
        <div className="footer-top-block-content-container">
          <ImagePass className="background-image" src={image} />
          <div className="footer-top-block-content">
            <div className="content" dangerouslySetInnerHTML={{__html: content}} />
            <GatsbyLink className="action" to={link.url} decode>
              {link.title}
            </GatsbyLink>
          </div>
        </div>
      </div>
    </OnVisible>
  )
}
