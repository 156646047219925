import React from 'react';
import OnVisible from 'react-on-visible';
import './TableBlock.scss';
import { decodeEntities } from '../../../utils/helpers';

export const TableBlock = (props) => {
  const {
    title,
    table,
    tableContentAlignment,
    content
  } = props;
  return (
    <OnVisible percent={5} wrappingElement="section" className="table-block">
      <div className="wrap">
        {title && <h2 className="table-block-title">{decodeEntities(title)}</h2>}
        {table && (
          <div className={`table-block-table-container ${tableContentAlignment}`}>
            <table className="table-block-table">
              {table.header && table.header.length > 0 && (
                <thead>
                  <tr>
                    {table.header.map((headerCol,index)=> {
                      return (
                        <th key={index}>{decodeEntities(headerCol.text)}</th>
                      );
                    })}
                  </tr>
                </thead>
              )}
              {table.body && table.body.length > 0 && (
                <tbody>
                  {table.body.map((bodyRow, bodyRowIndex) => {
                    return (
                      <tr key={bodyRowIndex}>
                        {bodyRow && bodyRow.length > 0 && bodyRow.map((bodyRowCol, index) => {
                          return (
                            <td key={index}>{decodeEntities(bodyRowCol.text)}</td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        )}
        {content && <div className="table-block-content" dangerouslySetInnerHTML={{__html: content}} />}
      </div>
    </OnVisible>
  )
}
