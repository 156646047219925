import React, { Component } from 'react';
import OnVisible from 'react-on-visible';
import GatsbyLink from '../../../GatsbyLink';
import './RelatedPass.scss';
import Carousel from '../../../Carousel';
import { Arrow } from '../../../Svg';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import PostListItem from '../../PostListItem'
import { PostListNavigation } from '../../PostListNavigation'

class ThisSlider extends Component {
  render () {
    const { posts, siteMetadata, pathPrefix, showAuthor } = this.props;
    const settings = {
      container: 'related-carousel',
      nav: false,
      mouseDrag: true,
      controls: true,
      loop: false,
      gutter: 20,
      slideBy: 1,
      controlsContainer: '.related-nav',
      responsive: {
        1600: {
          items: 3.3,
        },
        1024: {
          items: 3.3,
        },
        640: {
          items: 2.2,
        },
        350: {
          items: 1.2,
        }
      }
    };

    return (
      <div className="slider">
        <Carousel settings={settings}>
          {posts && posts.map((post, slide_count) => post.node.slug !== 'gatsby' &&
            <PostListItem key={slide_count} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
          )
        }
        </Carousel>
      </div>
    )
  }
}

class ListView extends Component {
  render () {
    const { posts, siteMetadata, pathPrefix, showAuthor } = this.props;
    return (
      <div className="slider list-view">
          {posts && posts.map((post, slide_count) => post.node.slug !== 'gatsby' &&
            <PostListItem
            key={slide_count}
            data={post.node}
            siteMetadata={siteMetadata}
            pathPrefix={pathPrefix}
            showAuthor={showAuthor}
            />
          )
        }
      </div>
    )
  }
}

export const RelatedPass = (props) => {
  const { data, title = 'Related Posts', introContent, listView, contentType = 'post' } = props;
  const { allWordpressPost, categoryFilter, allWordpressWpProjects, wordpressWpSettings, allWordpressCategory, siteSettings } = data;
  const postPass = allWordpressWpProjects ? allWordpressWpProjects : allWordpressPost
  const { showAuthor } = siteSettings.options
  const { edges: posts } = postPass
  const blogFeedLink = { url: `/${wordpressWpSettings.blogSlug}/` , title: 'View all posts' }
  // NOTE: HARDCODED PROJECT PAGE SLUG
  const projectFeedLink = { url: `/projects/` , title: 'View all projects' }
  const pathPrefix = contentType === 'project' ? 'projects' : wordpressWpSettings.blogSlug
  return (
    <OnVisible wrappingElement="section" percent={5} className={`post-feed ${contentType}-list`}>
      {listView && <PostListNavigation pathPrefix={wordpressWpSettings.blogSlug} categoryFilter={categoryFilter} />}
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            {!listView && title && <h3 className="title section-title" dangerouslySetInnerHTML={{ __html: title }} />}
            {!listView && introContent && <div className="intro-content" dangerouslySetInnerHTML={{ __html: introContent }} />}
            {!listView && <ThisSlider posts={posts} siteMetadata={wordpressWpSettings} pathPrefix={pathPrefix} showAuthor={showAuthor} />}
            {listView && <ListView posts={posts} siteMetadata={wordpressWpSettings} pathPrefix={pathPrefix} showAuthor={showAuthor} />}
            <div className="related-actions">
              <div className="related-nav">
                <button type="button" title="Prev"><Arrow direction="left" /></button>
                <button type="button" title="Next"><Arrow direction="right" /></button>
              </div>
              {contentType === 'post' && blogFeedLink && <GatsbyLink to={blogFeedLink.url} label={blogFeedLink.title} className="action" decode>{blogFeedLink.title}</GatsbyLink> }
              {contentType === 'project' && projectFeedLink && <GatsbyLink to={projectFeedLink.url} label={projectFeedLink.title} className="action" decode>{projectFeedLink.title}</GatsbyLink> }
            </div>
          </div>
        </div>
      </div>
    </OnVisible>

  )
}
