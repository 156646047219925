import React from 'react';
import { RelatedPosts } from '../../Posts/Related/RelatedPosts';
import { RelatedProjects } from '../../Posts/Related/RelatedProjects'

export const PostFeed = (props) => {
  if (props.contentType === 'project') {
    return <RelatedProjects {...props} />
  }
  if (props.contentType === 'post') {
    return <RelatedPosts {...props} />
  }
  return null
}
