import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import GatsbyLink from '../../GatsbyLink';
import { Arrow } from '../../Svg';
import './LinkList.scss';
import { decodeEntities } from '../../../utils/helpers';

export const LinkList = (props) => {
  let {
    title,
    introContent,
    links,
    useGlobal
  } = props;
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: wordpressAcfOptions(options: {}) {
        options {
          linkListTitle
          linkListIntroContent
          linkListLinks {
            link {
              url
              target
              title
            }
          }
        }
      }
    }
  `);
  const { options = {} } = siteSettings;
  const {
    linkListTitle = null,
    linkListIntroContent = null,
    linkListLinks = null
  } = options;
  if (useGlobal) {
    title = linkListTitle;
    introContent = linkListIntroContent;
    links = linkListLinks;
  }
  return (
    <OnVisible wrappingElement="section" percent={5} className="links-list">
      <div className="wrap">
        {title && <h2>{decodeEntities(title)}</h2>}
        {introContent && <div className="intro-content" dangerouslySetInnerHTML={{__html: introContent}} />}
        {links && links.length > 0 && (
          <div className="links-list-links">
            {links.map((link, index) => {
              if (!link.link) return null;
              const {
                url: linkUrl,
                target: linkTarget,
                title: linkTitle
              } = link.link;
              return (
                <GatsbyLink key={index} className="links-list-link" to={linkUrl}><span>{decodeEntities(linkTitle)}</span><Arrow /></GatsbyLink>
              );
            })}
          </div>
        )}
      </div>
    </OnVisible>
  );
};
