import React, { useState } from 'react';
import './ImageSlider.scss';
import OnVisible from 'react-on-visible';
import Carousel from '../../Carousel';
import { Image } from '../../Image';
import { Arrow } from '../../Svg/Arrow';

export const ImageSlider = (props) => {
  const [slider, setSlider] = useState(null);
  const {
    images
  } = props;
  const settings = {
    container: 'image-slider-carousel',
    nav: false,
    mouseDrag: true,
    controls: false,
    loop: false,
    slideBy: 1,
    gutter: 20,
    items: 3,
    nextArrow: 'next-arrow',
    prevArrow: 'prev-arrow',
    onInit: (thisSlider) => {
      if (!slider && thisSlider) {
        setSlider(thisSlider);
      }
    },
    responsive: {
      1080: {
        items: 3
      },
      700: {
        items: 2
      },
      0: {
        items: 1
      }
    }
  };
  return (
    <OnVisible wrappingElement="section" className="image-slider">
      <div className="wrap">
        <Carousel settings={settings} ref={slider}>
          {images.map((image, index) => {
            return (
              <div key={index}>
                <Image src={image.source_url} position="relative" />
              </div>
            );
          })}
        </Carousel>
        <button className="prev-arrow" onClick={() => slider.goTo('prev')} type="button">
          <Arrow direction="left" />
        </button>
        <button className="next-arrow" onClick={() => slider.goTo('next')} type="button">
          <Arrow direction="right" />
        </button>
      </div>
    </OnVisible>
  )
}
