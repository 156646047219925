import React from 'react';
import OnVisible from 'react-on-visible';
import './InnerPageLinks.scss';
import { Image } from '../../Image';
import { decodeEntities } from '../../../utils/helpers';
import GatsbyLink from '../../GatsbyLink';
import { Arrow } from '../../Svg';

export const InnerPageLinks = (props) => {
  const {
    title,
    pageLinks
  } = props;
  return (
    <OnVisible percent={5} wrappingElement="section" className="inner-page-links">
      <div className="wrap">
        {title && <h2 className="inner-page-links-title">{decodeEntities(title)}</h2>}
        {pageLinks && (
          <div className="inner-page-links-container">
            {pageLinks.map(pageLink => {
              const { image, pageLink: link } = pageLink;
              return (
                <GatsbyLink className="inner-page-link" to={link.url}>
                  <Image className="background-image" src={image} />
                  <span className="link" to={link.url}><span>{decodeEntities(link.title)}</span><Arrow /></span>
                </GatsbyLink>
              );
            })}
          </div>
        )}
      </div>
    </OnVisible>
  )
}
